.Announcements {
    width: 50%;
    padding: 0;
}

.Announcements > li {
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}


.Announcements img {
    display: inline-block;
    margin: 0 10px;
    border-radius: 40px;
    padding: 5px;
    vertical-align: middle;

}

@media screen and (max-width: 600px) {
    .Announcements {
        width: 100%;
    }
    .Announcements img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
  }

.Announcements a {
    text-decoration: none;
}

.Announcements-title {
    font-size: calc(10px + 2vmin);
    color: white;
    display: inline-block;
    vertical-align: middle;
}

.Announcements-title time {
    font-size: 75%;
}