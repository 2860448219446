.Rainbow {

    background: linear-gradient(rgba(0,0,0,0), red , orange, yellow, green, blue, purple, rgba(0,0,0,0));
    padding: 10px 0;
}

@media screen and (min-width: 600px) {
    .Rainbow {
        position: fixed;
        bottom: 40px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
    }
}

.Rainbow-link {
    display: inline-block;
    margin: 0 10px;
    padding: 5px;
}

.Rainbow-home {
    transform: rotate(180deg); 
}

@media screen and (max-width: 600px) {
    .Rainbow {
        margin-bottom: 40px;
        position: relative;
    }
    .Rainbow-link {
        margin: 0 2px;
        display: block;
        position: absolute;
        right: 10px;
        bottom: -60px;
        width: 64px;
        opacity: 0.5;
    }
    .Rainbow-link:hover {
        opacity: 1;
    }

    .Rainbow-home {
        left: 10px;
    }
  }
  