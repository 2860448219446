.GodIcon {
    display: inline-block;
    margin: 0 10px;
    background-color:rgba(0, 0, 0, 0.6);
    border-radius: 40px;
    padding: 5px;
}

@media screen and (max-width: 600px) {
    .GodIcon {
        margin: 5px;
    }
}