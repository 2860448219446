.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-width: 100%;
    height: auto;
    image-rendering: pixelated;
}

@media screen and (min-width: 600px) {
    .carousel .slide img {
        height: 80vh;
        max-width: 95%;
        max-height: 80vh;
        width: auto !important;
    }
}