.Home-logo {
    height: 40vmin;
  }
  
@media screen and (max-width: 600px) {
    .Home-logo {
        width: 50%;
        height: 50%;
    }
}

.Home {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Home-secret {
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: monospace;
    text-shadow: 0 0 20px rgba(255,255,255,1);
}