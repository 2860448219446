.Page {
    text-align: left;
    color: white;
    margin: 0;
    background: rgba(0, 0, 0, 0.5);
}

.Page-image {
    width: 100%;
}

.Page a {
    color: yellowgreen;
}

@media screen and (min-width: 600px) {
    .Page {
        margin: 0 40px 20vh;
    }
    .Page-image {
        float: right;
        padding: 40px;
        width: 300px;
        margin: 0;
    }
    .Page-swipe-hint {
        display: none;
    }
}